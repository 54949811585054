'use client';

type LegacyProps = {
    pageType: 'Search2' | 'Category2';
};

export const Legacy = (props: LegacyProps) => {
    const { pageType } = props;

    if (typeof window !== 'undefined') {
        // value used by monitoring software MPulse to distinguish between
        // Legacy Search page and Search Modernization page
        window.pageType = pageType;

        console.log(`pageType: ${window.pageType}`);
    }

    return null;
};
